import { NextRouter, useRouter } from 'next/router';
import React from 'react';
import { IS_MAINTENANCE, UserRoleEnum } from '@app/common';
import { observer } from 'mobx-react-lite';
import { routes } from '../../config/routes';
import { useRootStore } from '../../providers/root-store-provider';
import { RootStore } from '../stores/root-store';
import { UserProfileMobxDto } from '../mobx/dtos/user/user-profile-mobx-dto';
import { LookupKeyEnum } from '../../defs/api';

export const hasAdminRole = (userProfile: UserProfileMobxDto | null) => userProfile?.role === UserRoleEnum.Admin;

/**
 * Pokud je aplikace v maintenance, tak uživatel bude přesměrován na stránku maintenance
 */
const restrictMaintenancePage = (router: NextRouter) => {
    if (IS_MAINTENANCE) {
        router.push(routes.web.maintenance);
        return true;
    }
    return false;
};

/**
 * Pokud je uživatel v procesu odhlašování
 */
const restrictLoggingOut = (rootStore: RootStore) => {
    const { cookieService, authStore } = rootStore;
    if (!cookieService.hasJwtToken() && authStore.isLoggingOut) {
        authStore.endLogout();
        return true;
    }
    return false;
};

/**
 * Premium uživatel by měl být z ceníku přesměrován do aplikace
 */
export const restrictPremiumUserAccessPricingPage = (router: NextRouter, rootStore: RootStore): boolean => {
    const isOnPricingPage = router.asPath === routes.app.pricing;

    if (rootStore.authStore.userHasPremiumTariff && isOnPricingPage) {
        router.push(routes.app.index);
        return true;
    }

    return false;
};

/**
 * Nepřihlášený uživatel se nesmí dostat na protectedPage
 */
export const restrictProtectedPage = (router: NextRouter, rootStore: RootStore): boolean => {
    if (!rootStore.authStore.userProfile && !rootStore.cookieService.hasJwtToken()) {
        rootStore.cookieService.setRedirectUrl(router.asPath);
        router.push(`${routes.web.index}?login=true`);
        return true;
    }
    return false;
};

/**
 * Uživatel, který je na wainting listu se nesmí dostat do aplikace
 */
export const restrictProtectedPageForUserOnWaitingList = (router: NextRouter, rootStore: RootStore) => {
    const isOnWaitingList = rootStore.authStore.userProfile?.isOnWaitingList || false;
    if (isOnWaitingList) {
        router.push(routes.web.waitingList);
        return true;
    }

    return false;
};

/**
 * Free uživatel se může dostat do aplikace, pouze pokud byl registrován před 23.6.2024.
 * Nicméně nemůže se dostat na sdílené portfolio.
 */
export const restrictFreeUserAccessProtectedPage = (router: NextRouter, rootStore: RootStore): boolean => {
    const isOnPricingPage = router.asPath === routes.app.pricing;
    const isOnSharedPortfolioPage = router.asPath.includes(routes.app.sharedPortfolio);

    if (
        !rootStore.authStore.userHasPremiumTariff &&
        (!rootStore.authStore.userProfile?.freeSubscriptionAvailable ||
            (rootStore.authStore.userProfile?.freeSubscriptionAvailable && isOnSharedPortfolioPage)) &&
        !isOnPricingPage
    ) {
        router.push(routes.app.pricing);
        return true;
    }
    return false;
};

/**
 *  Pouze premium uživatel může přistupovat na premiumPage
 */
export const restrictAccessToPremiumPage = (router: NextRouter, rootStore: RootStore) => {
    if (!rootStore.authStore.userHasPremiumTariff) {
        router.push(routes.app.index);
        return true;
    }
    return false;
};

/**
 * Pouze administrátor může přistupovat na adminPage
 */
export const restrictAccessAdminPage = (router: NextRouter, rootStore: RootStore) => {
    if (!hasAdminRole(rootStore.authStore.userProfile)) {
        router.push(routes.app.index);
        return true;
    }
    return false;
};

/**
 * Pokud je uživatel na pricing page a má zájem o předplatné, tak se přesměruje na checkout
 */
export const restrictAccessToPricingPageByInterestedInSubscription = (rootStore: RootStore) => {
    if (!rootStore.authStore.userHasPremiumTariff) {
        if (process.env.NEXT_PUBLIC_IS_WAITING_LIST === 'true') {
            const interestedInSubscription = rootStore.authStore.userProfile?.interestedInSubscription;
            if (interestedInSubscription || rootStore.stripeStore.creatingCheckoutSession) {
                if (interestedInSubscription) {
                    rootStore.userStore.updateInterestedInSubscription(null);
                    rootStore.stripeStore.createCheckoutSession(interestedInSubscription as string as LookupKeyEnum);
                }
                return true;
            }
        } else {
            const interestedInSubscription = rootStore.cookieService.getInterestedInSubscription();
            if (interestedInSubscription || rootStore.stripeStore.creatingCheckoutSession) {
                if (interestedInSubscription) {
                    rootStore.cookieService.setInterestedInSubscription(null);
                    rootStore.stripeStore.createCheckoutSession(interestedInSubscription);
                }
                return true;
            }
        }
    }

    return false;
};

export function withAuth<T extends object>(
    component: React.FC<T>,
    protectedPage = true,
    premiumPage = false,
    adminPage = false,
) {
    return observer((props: T) => {
        const router = useRouter();
        const pricingPage = router.asPath === routes.app.pricing;

        const rootStore = useRootStore();

        if (restrictMaintenancePage(router)) {
            return null;
        }

        if (restrictLoggingOut(rootStore)) {
            rootStore.authStore.endLogout();
            return component(props);
        }

        if (protectedPage) {
            if (restrictProtectedPage(router, rootStore)) {
                return null;
            }

            if (restrictProtectedPageForUserOnWaitingList(router, rootStore)) {
                return null;
            }

            if (restrictPremiumUserAccessPricingPage(router, rootStore)) {
                return null;
            }

            if (restrictFreeUserAccessProtectedPage(router, rootStore)) {
                return null;
            }

            if (premiumPage) {
                if (restrictAccessToPremiumPage(router, rootStore)) {
                    return null;
                }
            }

            if (adminPage) {
                if (restrictAccessAdminPage(router, rootStore)) {
                    return null;
                }
            }

            if (pricingPage) {
                if (restrictAccessToPricingPageByInterestedInSubscription(rootStore)) {
                    return null;
                }
            }

            // pokud mám přístup na protectedPage a mám redirectCookie, tak přesměruji na danou stránku
            rootStore.cookieService.loadRedirectUrl();
        }

        return component(props);
    });
}
