import Image from 'next/image';
import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import { List, Wrapper, Row, InteractiveBrokers, Etoro } from './partners.styled';
// import { Text } from '../../app/text/text';

export const Partners: React.FC = () => {
    // const { t } = useTranslation();

    return (
        <Wrapper>
            {/* <Text size="xlarge" semiBold>
                {t('pages.homepage.partners.title')}
            </Text> */}
            <List>
                <Row>
                    <Image src={`/images/partners/xtb.svg`} alt="XTB" width="130" height="50" layout="intrinsic" />
                    <InteractiveBrokers>
                        <Image
                            src={`/images/partners/interactive-brokers.svg`}
                            alt="Interactive Brokers"
                            width="210"
                            height="30"
                            layout="intrinsic"
                        />
                    </InteractiveBrokers>
                    <Image
                        src={`/images/partners/trading-212.svg`}
                        alt="Trading 212"
                        width="172"
                        height="22"
                        layout="intrinsic"
                    />
                </Row>
                <Row>
                    <Etoro>
                        <Image
                            src={`/images/partners/etoro.svg`}
                            alt="eToro"
                            width="120"
                            height="38"
                            layout="intrinsic"
                        />
                    </Etoro>
                    <Image
                        src={`/images/partners/degiro.svg`}
                        alt="Degiro"
                        width="156"
                        height="22"
                        layout="intrinsic"
                    />
                </Row>
            </List>
        </Wrapper>
    );
};
