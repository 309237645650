import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Partners } from '../../../../components/web/partners/partners';
import { Buttons, Content, ImageWrapper, Inner, Wrapper } from './intro-homepage.styled';
import { Container } from '../../../../components/web/container/container';
import { HeroHeadline } from '../../../../components/web/hero/headline/hero-headline';
import { HeroText } from '../../../../components/web/hero/text/hero-text';
import { Button } from '../../../../components/app/form/button/button';
import { IconNext } from '../../../../components/app/icons/next';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { Responsive } from '../../../../components/app/responsive/responsive';

export const IntroHomepage: React.FC = () => {
    const rootStore = useRootStore();
    const { authStore } = rootStore;
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <Inner>
                    <Content>
                        <HeroHeadline>
                            <Trans i18nKey="pages.homepage.hero.title" components={{ span: <span /> }} />
                        </HeroHeadline>
                        <HeroText>
                            <Trans i18nKey="pages.homepage.hero.subtitle" components={{ strong: <strong /> }} />
                        </HeroText>

                        <Responsive breakpoint={'vl'} type={'max'}>
                            <ImageWrapper>
                                <Image
                                    src={`/images/intro/preview.jpg`}
                                    alt="Monery"
                                    width="1363"
                                    height="958"
                                    layout="responsive"
                                    priority
                                />
                            </ImageWrapper>
                        </Responsive>
                        <Buttons>
                            <Button
                                variant="secondary"
                                icon={<IconNext />}
                                iconGap="small"
                                size="vlarge"
                                onClick={() => {
                                    authStore.authDialogStore.setSignupActive();
                                }}
                            >
                                {t('pages.homepage.hero.getStarted')}
                            </Button>
                            <Button
                                href={routes.web.featureList}
                                variant="white"
                                variation="outlined"
                                size="vlarge"
                                block
                            >
                                {t('pages.homepage.hero.learnMore')}
                            </Button>
                        </Buttons>
                    </Content>
                    <Responsive breakpoint={'vl'}>
                        <ImageWrapper>
                            <Image
                                src={`/images/intro/preview.jpg`}
                                alt="Monery"
                                width="1363"
                                height="958"
                                layout="responsive"
                                priority
                            />
                        </ImageWrapper>
                    </Responsive>
                </Inner>
                <Partners />
            </Container>
        </Wrapper>
    );
};
