import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 4.5rem;

        @media (max-width: 768px) {
            gap: 3rem;
        }

        p {
            color: ${theme.palette.color.white.main};
            margin: 0;
            max-width: 60rem;
        }
    `,
);

export const List = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6.4rem;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6.4rem;

    @media (max-width: 768px) {
        width: 100%;
        gap: 2.4rem;

        img {
            max-height: 24px !important;
        }
    }
`;

export const InteractiveBrokers = styled.div`
    position: relative;
    top: -0.5rem;
`;

export const Etoro = styled.div`
    position: relative;
    top: -0.5rem;
`;
